<template>
  <router-view />
</template>

<script>
  export default {
    name: 'App',
    head: {
	    titleTemplate: '%s',
	    title: process.env.VUE_APP_NAME,
      htmlAttrs: { lang: 'es' },
	    meta: [
	      { charset: 'utf-8' },
	      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
         { name: 'description', content: 'Todo cartas online es un application dirigida a restaurantes la cual contiene un módulo de carta electrónica donde se puede consultar la carta desde cualquier dispositivo, tus clientes pueden ver la carta de tu restaurante desde sus propios dispositivos móviles. No hace falta que los clientes instalen nada en su dispositivo, solo leerán un código QR y ya tienen disponible la carta.'},
	    ],
	    // link: [
	    //   { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' }
	    // ]
  	}
  }
</script>
<style lang="sass"  >
  .full .v-overlay__content
    position: absolute !important
    bottom: 50px

  .imagezone
    z-index: 999
    cursor: pointer
    transition: all .15s ease
    text-align: center
    color: #283145
    border: 2px dashed #2c364c
    border-radius: .375rem
    background-color: #fff


  .imagezone:hover
    color:#525f7f
    border-color:#8898aa
    background-color: #fff

</style>
