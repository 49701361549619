const en = {
	btns:{
		carta: 'Letter',
		menu: 'Menus',
		promociones: 'Promotions',
		info: 'Info',
		secciones: 'Sections',
		alergenos: 'Allergens',
		aplicar: 'Apply',
    select_todos: 'Select all',
    shooping_cart: 'Cart',
    reservas: 'Booking'
	},
	titulos:{
		secciones: 'Sections',
		alergenos: 'Allergens',
		detalles: 'Details',
		ubicacion: 'Location',
	},
	plato:{
		precio: 'Price',
		promociones: 'Promotions',
  },
  add_shopping_cart: 'Add Shopping Cart'

};

export default en;
