const zh = {
	btns:{
		carta: '信',
		menu: '菜单',
		promociones: '促销活动',
		info: '信息',
		secciones: '部分',
		alergenos: '过敏原',
		aplicar: '应用',
    select_todos: '全选',
    shooping_cart: '购物车',
    reservas: '预订'
	},
	titulos:{
		secciones: '部分',
		alergenos: '过敏原',
		detalles: '细节',
		ubicacion: '位置',
	},
	plato:{
		precio: '价钱',
		promociones: '促销活动',
	}
};

export default zh;
