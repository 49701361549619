import axios from 'axios'
import store from '../store'
import router from '../router'

const service = axios.create({
   	baseURL: process.env.VUE_APP_BASE_API,
    headers: {'Content-Type': 'application/json'}
});
// console.log('Api',process.env)
service.interceptors.response.use(function (response) {
   return response
}, function (error) {

 	if (error.response.status && error.response.status === 401) {
   		store.dispatch('closeSesion').then(()=>{
	    	router.push({ path: '/login' });
	    });
  	}

    return Promise.reject(error)
})

export default service;
