<template>
    <v-dialog
      v-model="internalValue"
      :max-width="width"
      persistent
      scrollable
    >
      <v-card :loading="loadcard">
        <v-card-title class="py-1 info lighten-3" style="text-align: left">
          <v-list-item class="pa-0">
            <v-list-item-icon class="mr-1">
              <v-icon left color="info lighten-1" class="modal" v-text="icon" />
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="white--text primary--text" v-text="title"/>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon x-small @click="close">
                <v-icon class="modal" color="info lighten-1">mdi-close</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-title>

        <v-card-text class="py-0">
          <v-container>
            <slot name="content"></slot>
          </v-container>
        </v-card-text>

        <v-card-actions class="grey lighten-5">
          <v-spacer />
          <slot name="actions"></slot>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
  export default {
    name: 'BaseMaterialModal',

    props: {
      dismissible: {
        type: Boolean,
        default: true,
      },
      icon: {
        type: String,
        default: '',
      },
      width: {
        type: String,
        default: '500',
      },
      title: {
        type: String,
        default: '',
      },
      loadcard: {
        type: Boolean,
        default: false,
      },
      value: Boolean,
    },

    data () {
      return {
        internalValue: this.value,
      }
    },

    watch: {
      internalValue (val, oldVal) {
        if (val === oldVal) return

        this.$emit('input', val)
      },
      value (val, oldVal) {
        if (val === oldVal) return

        this.internalValue = val
      },
    },
    methods:{
      close(){
        this.$emit('input', false);
        this.$emit('hidde');
      }
    }
  }
</script>

<style lang="sass" scope>
  .modal
    position: relative !important
    top: 0 !important
    right: 0 !important
    color: #999 !important
    opacity: 1 !important
    font-size: 22px !important

</style>

