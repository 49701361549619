import Cookies from 'js-cookie'

export function cookieFromRequest (req, key) {
  if (!req.headers.cookie) {
    return
  }

  const cookie = req.headers.cookie.split(';').find(
    c => c.trim().startsWith(`${key}=`)
  )

  if (cookie) {
    return cookie.split('=')[1]
  }
}

export function SET_USER(user){
	Cookies.set('User', JSON.stringify(user));
}

export function SET_TOKEN(token){
	Cookies.set('Token', token);
}

export function SET_LANGUAGE(lang){
	Cookies.set('Language', lang);
}

export function SET_PERMISSIONS(permissions){
	Cookies.set('PermissionsAll', JSON.stringify(permissions));
}

export function GET_USER(){
	const user = Cookies.get('User');

	return user ? JSON.parse(user) : null;
}

export function GET_TOKEN(){
	const token = Cookies.get('Token');

	return token ? token : null;
}

export function GET_LANGUAGE(){
	const lang = Cookies.get('Language');

	return lang ? lang : null;
}

export function GET_PERMISSIONS(){
	const permissions = Cookies.get('PermissionsAll');

	return permissions ? JSON.parse(permissions) : [];
}

export function REMOVE_USER(){
	Cookies.remove('User');
	Cookies.remove('Token');
	Cookies.remove('PermissionsAll');
}


