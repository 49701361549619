const landing = {
  namespaced: true,
  state: {
   color: '',
   logo: '',
   header: '',
   name: '',
   address: '',
   city: '',
   province:'',
   facebook: '',
   instagram: '',
   whatsapp: '',
   description: '',
   phone: '',
   email: '',
   lat: '',
   lng: '',
   url_allergen: '',
   restaurant_id:null,
   coin:'',
   coins:[],
   shoppingCart:{
    cliente:[],
    reservation:false,
    productos:[],
    reservas:[],
    coin:null,
    cant_comensales:0,
    total: 0
  },
  booking_temp :{
  },
  symbol:''
  },
  getters: {
    coins(state){
      return state.coins;
    },
    logo(state) {
      return state.logo ? `${process.env.VUE_APP_IMG_URL_API}images/restaurants/${state.logo}` : '';
    },
    header(state) {
      return state.header ? `${process.env.VUE_APP_IMG_URL_API}images/restaurants/${state.header}` : '';
    },
    name(state) {
      return state.name;
    },
    description(state) {
      return state.description;
    },
    url_allergen(state) {
      return state.url_allergen;
    },
    address(state) {
      return state.address;
    },
    city(state) {
      return state.city;
    },
    province(state) {
      return state.province;
    },
    facebook(state) {
      return state.facebook;
    },
    instagram(state) {
      return state.instagram;
    },
    whatsapp(state) {
      return state.whatsapp;
    },
    phone(state) {
      return state.phone;
    },
    email(state) {
      return state.email;
    },
    lat(state) {
      return parseFloat(state.lat);
    },
    lng(state) {
      return parseFloat(state.lng);
    },
    coin(state){
      return state.coin;
    },
    symbol(state){
      return state.symbol;
    },
    totalcart(state){
      return state.shoppingCart.total;
    },
    shoppingCart(state){
      return state.shoppingCart;
    },
    shoppingCartFinal(state){
      let shoppingcart = state.shoppingCart;
      let cliente = shoppingcart.cliente;
      let booking = shoppingcart.reservas;
      let products = shoppingcart.productos;
      let reservation = shoppingcart.reservation;
      var hoy = new Date();
      var fecha = hoy.getFullYear() + '-' + ( hoy.getMonth() + 1 ) + '-' + hoy.getDate();
      let result = {
        name :cliente.name,
        last_name:cliente.last_name,
        email:cliente.email,
        telephone:cliente.telephone,
        reservation:reservation,
        date_order: fecha,
        coin:state.coin,
        booking:booking,
        products:products
        }
        return result;

    }
  },
  mutations: {

    SET_CONFIG(state, payload) {
      //console.log('insercción de info')
      //console.log(payload)
      state.logo    = payload.logo;
      state.header  = payload.image_header;
      state.description = payload.description;
      state.name    = payload.name;
      state.address = payload.address;
      state.city = payload.city;
      state.province = payload.province;
      state.facebook = payload.facebook;
      state.instagram = payload.instagram;
      state.whatsapp = payload.whatsapp;
      state.phone = payload.phone_number;
      state.email = payload.email;
      state.lat = payload.lat;
      state.lng = payload.lon;
      state.url_allergen = payload.restaurant_setup.url_allergen;
      state.coins = payload.restaurant_setup.coins;
      state.restaurant_id = payload.id;
    },
    SET_COLOR(state, color){
      state.color = color;
    },
     SET_COIN (state,payload) {
      state.coin = payload.cod;
      state.symbol = payload.symbol
    },
    SET_COINS(state,payload){
      state.coins = payload;
    },
    ADD_PERSON(state,payload){
      let cliente = payload.cliente;
      let reservation = payload.reservation;
      state.shoppingCart.cliente = cliente;
      state.shoppingCart.reservation = reservation;
      let booking_tmp = state.booking_temp;
      if (Object.keys(booking_tmp).length !== 0 && reservation == true) {
        booking_tmp.name = cliente.name;
        booking_tmp.email = cliente.email;
        booking_tmp.phone = cliente.telephone;
        booking_tmp.last_name = cliente.last_name;
        booking_tmp.diners = state.shoppingCart.cant_comensales;
        state.shoppingCart.reservas = booking_tmp;
      }

    },
    ADD_BOOKING_TEMP(state,payload){
      state.booking_temp = payload;
    },
    ADD_ITEM_CART(state,payload){
      let exist = false;
      let productos = [];

      if (state.shoppingCart.productos.length > 0) {
        productos =  state.shoppingCart.productos.map(function(producto){
          if(producto.id == payload.id && producto.name == payload.name && producto.price == payload.price && producto.type == payload.type  ){
            producto.cant += payload.cant;
            exist = true;
          }
          return producto;
        });
        if (!exist) {
          state.shoppingCart.productos.push(payload);
        } else {
          state.shoppingCart.productos = productos;
        }
      } else {
        state.shoppingCart.productos.push(payload);
      }
      state.shoppingCart.total += payload.cant;
    },
    DELETE_ITEM_CART(state,payload){
      let productos = [];
      let cantDelete = 0;
      productos =  state.shoppingCart.productos.filter(function(producto){
        let exist = false;
        if(producto.id == payload.id && producto.name == payload.name && producto.price == payload.price && producto.type == payload.type  ){
          exist = true;
          cantDelete = producto.cant;
        }
        if (!exist) {
          return producto;
        }
      });
      state.shoppingCart.productos = productos;
      state.shoppingCart.total -= cantDelete;

    },
    SET_RESERVATION(){

    },
    SET_COMENSALES(state,payload){
      state.shoppingCart.cant_comensales = payload;

    }
  },
}

export default landing;
