import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import requestApi from '../resources/request'
//import { component } from 'vue/types/umd';

const permit = (permissionName) => {
  var Permissions = store.getters['auth/permissions'];
  return Permissions.indexOf(permissionName) !== -1;
};

const interopDefault = (promise) => {
  return promise.then(m => m.default || m)
}

/*
/ RUTAS ROL ADMINISTRADOR
 */
const layout_dashboard = () => interopDefault(import('@/views/dashboard/Index' /* webpackChunkName: "layout_dashboard" */))
const users = () => interopDefault(import('@/views/adminastrator/Users' /* webpackChunkName: "users" */))
const alergenos = () => interopDefault(import('@/views/adminastrator/alerganos' /* webpackChunkName: "alergenos" */))
const restaurant_create = () => interopDefault(import('@/views/adminastrator/restaurant_create' /* webpackChunkName: "restaurant_create" */))
const restaurants_list = () => interopDefault(import('@/views/adminastrator/restaurants' /* webpackChunkName: "restaurants" */))
const layout_restaurant_admin = () => interopDefault(import('@/views/adminastrator/restaurant_admin' /* webpackChunkName: "restaurant_admin" */))
const dashboard = () => interopDefault(import('@/views/dashboard/Dashboard' /* webpackChunkName: "dashboard" */))
/*
/ RUTAS PUBLICAS
 */
const login = () => interopDefault(import('@/views/autentication/login' /* webpackChunkName: "login" */))
const forget = () => interopDefault(import('@/views/autentication/forgetPassword' /* webpackChunkName: "forget" */))
const resetPassword = () => interopDefault(import('@/views/autentication/resetPassword' /* webpackChunkName: "reset_password" */))
// const register = () => interopDefault(import('@/views/autentication/register' /* webpackChunkName: "register" */))
// const register_process = () => interopDefault(import('@/views/autentication/process' /* webpackChunkName: "register_process" */))
const register_verificate = () => interopDefault(import('@/views/autentication/verificate' /* webpackChunkName: "register_verificate" */))
const layout_auth = () => interopDefault(import('@/layout/auth' /* webpackChunkName: "layout_auth" */))
/*
/ RUTAS ROL DUEÑO DE RESTAURANTE
 */
const dishe = () => interopDefault(import('@/views/restaurant/dishes/create' /* webpackChunkName: "dishe_create" */))
const dishe_list = () => interopDefault(import('@/views/restaurant/dishes/list' /* webpackChunkName: "dishe_list" */))
const dishe_categories = () => interopDefault(import('@/views/restaurant/dishes/categories' /* webpackChunkName: "dishe_categories" */))
const menu_create = () => interopDefault(import('@/views/restaurant/menu/create' /* webpackChunkName: "menu_create" */))
const menu_list = () => interopDefault(import('@/views/restaurant/menu/list' /* webpackChunkName: "menu_list" */))
const promotion_create = () => interopDefault(import('@/views/restaurant/promotions/create' /* webpackChunkName: "promotions_create" */))
const promotion_list = () => interopDefault(import('@/views/restaurant/promotions/list' /* webpackChunkName: "promotions_list" */))
const horarios_list = () => interopDefault(import('@/views/restaurant/horarios/list' /* webpackChunkName: "horarios_list" */))
const reservas_list = () => interopDefault(import('@/views/restaurant/horarios/reservaslist' /* webpackChunkName: "reservas_list" */))
const settings = () => interopDefault(import('@/views/restaurant/settings' /* webpackChunkName: "settings" */))
const error_admin = () => interopDefault(import('@/views/restaurant/ErrorDashboard' /* webpackChunkName: "error_admin" */))
/*
/ RUTAS LANDING
 */
const landing = () => interopDefault(import('@/views/landing/Index' /* webpackChunkName: "landing" */))
const error_landing = () => interopDefault(import('@/views/landing/ErrorLanding' /* webpackChunkName: "error_landing" */))
const letter = () => interopDefault(import('@/views/landing/letter' /* webpackChunkName: "letter" */))
const booking = () => interopDefault(import('@/views/landing/booking' /* webpackChunkName: "booking" */))
const dishe_info = () => interopDefault(import('@/views/landing/Dishe' /* webpackChunkName: "dishe_info" */))
const menu_info = () => interopDefault(import('@/views/landing/Menu_info' /* webpackChunkName: "Menu_info" */))
const menus = () => interopDefault(import('@/views/landing/Menus' /* webpackChunkName: "Menu" */))
const promotions = () => interopDefault(import('@/views/landing/Promotions' /* webpackChunkName: "Promotions" */))
const info = () => interopDefault(import('@/views/landing/Info' /* webpackChunkName: "Info" */))
const shopping_cart = () => interopDefault(import('@/views/landing/ShoppingCart' /* webpackChunkName: "ShoppingCart" */))

const home = () => interopDefault(import('@/views/landing/Home' /* webpackChunkName: "Home" */))
const indexdashboard = () => interopDefault(import('@/views/dashboard/IndexDashboard' /* webpackChunkName: "IndexDashboard" */))
const test = () => interopDefault(import('@/views/Test' /* webpackChunkName: "Test" */))

Vue.use(Router)

const routes =  new Router({
  mode: 'history',
  base: decodeURI('/'),
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) return { selector: to.hash }
    if (savedPosition) return savedPosition

    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: home,
      meta:{ auth: false},
    },
    {
      path: '/test',
      component: test,
      meta:{ auth: false},
    },
    {
      path: '/auth',
      component: layout_auth,
      redirect: '/auth/login',
      meta:{ auth: false},
      children:[
        // {
        //   path:'registro',
        //   name: 'registro',
        //   meta:{ auth: false},
        //   component: register_process
        // },
        {
          path:'olvido-contrasena',
          name: 'forget',
          meta:{ auth: false},
          component: forget
        },
        {
          path:'cambiar-contrasena/:code',
          name: 'resetPassword',
          meta:{ auth: false},
          component: resetPassword,
          beforeEnter: (to, from, next) => {
            if(to.params.code){
              next();
            }
            else{
              next('/404');
            }
          },
        },
        {
          path:'verificar-cuenta/:code',
          name: 'verificar',
          meta:{ auth: false},
          component: register_verificate,
          beforeEnter: (to, from, next) => {
            if(to.params.code){
              next();
            }
            else{
              next('/404');
            }
          },
        },
        {
          path:'login',
          name: 'login',
          meta:{ auth: false},
          component: login
        },
      ]
    },
    // {
    //   path:'/auth/registrar',
    //   name: 'register',
    //   meta:{ auth: false},
    //   component: register
    // },
    {
      path: '/r/:landing',
      component: landing,
      name:'landing',
      meta:{ auth: false, permission: 'landing'},
      children:[
        {
          path: 'cartas',
          component: letter,
          name:'letter',
          meta:{ auth: false, permission: 'landing'},
        },
        {
          path: 'reservas',
          component: booking,
          name:'booking',
          meta:{ auth: false, permission: 'landing'},
        },
        {
          path: 'menus',
          component: menus,
          name:'menus-landing',
          meta:{ auth: false, permission: 'landing'},
        },
        {
          path: 'promociones',
          component: promotions,
          name:'promotions-landing',
          meta:{ auth: false, permission: 'landing'},
        },
        {
          path: 'info',
          component: info,
          name:'info-landing',
          meta:{ auth: false, permission: 'landing'},
        },
        {
          path: 'shopping-cart',
          component: shopping_cart,
          name: 'shopping-cart',
          meta:{ auth: false, permission: 'landing'},
        },
      ]
    },
    {
      path: '/r/:landing/plato/:id',
      component: dishe_info,
      name:'dishe-details',
      meta:{ auth: false, permission: 'landing'},
      beforeEnter: (to, from, next) => {
        let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.id)){
          next();
        }
        else{
          next('/404');
        }
      },
    },
    {
      path: '/r/:landing/menu/:id',
      component: menu_info,
      name:'menu-details',
      meta:{ auth: false, permission: 'landing'},
      beforeEnter: (to, from, next) => {
        let Exp = /^([0-9])*$/;
        if(Exp.test(to.params.id)){
          next();
        }
        else{
          next('/404');
        }
      },
    },
    {
      path: '/404',
      component: error_landing,
      name: '404',
      meta:{ auth: false, permission: 'landing'},
    },
    {
      path: '/tc-admin',
      name:'Administration',
      redirect: '/tc-admin/dashboard',
      meta:{ auth: true, permission: 'default'},
      component: layout_dashboard,
      children:[
        {
          path: 'principal',
          component: indexdashboard,
          name:'principal',
          meta:{ auth: true, permission: 'default'},
        },
        {
          path: 'dashboard',
          component: dashboard,
          name:'dashboard',
          meta:{ auth: true, permission: 'default'},
        },
        {
          path: 'restaurantes',
          component: restaurants_list,
          name:'Restaurantes',
          meta:{ auth: true, permission: 'Mostrar restaurante'},
        },
        {
          path: 'restaurante/:id/administrar',
          component: layout_restaurant_admin,
          name:'Restaurante-Admin',
          meta:{ auth: true, permission: 'default'},
          beforeEnter: (to, from, next) => {
            let Exp = /^([0-9])*$/;
            if(Exp.test(to.params.id)){
              next();
            }
            else{
              next('/tc-admin/error');
            }
          },
          children:[
            {
              path: 'categorias',
              name:'Categorías',
              component: dishe_categories,
              meta:{ auth: true, permission: 'default'},
            },
            {
              path: 'plato/:option',
              name:'plato-registrar',
              component: dishe,
              meta:{ auth: true, permission: 'default'},
              beforeEnter: (to, from, next) => {
                let Exp = /^([0-9])*$/;
                if(Exp.test(to.params.option) || to.params.option === 'crear'){
                  next();
                }
                else{
                  next('/tc-admin/error');
                }
              }
            },
            {
              path: 'platos',
              name:'platos',
              component: dishe_list,
              meta:{ auth: true, permission: 'default'},
            },
            {
              path: 'promocion/:promotion',
              name:'promocion-registrar',
              component: promotion_create,
              meta:{ auth: true, permission: 'default'},
              beforeEnter: (to, from, next) => {
                let Exp = /^([0-9])*$/;
                if(Exp.test(to.params.promotion) || to.params.promotion === 'crear'){
                  next();
                }
                else{
                  next('/tc-admin/error');
                }
              }
            },
            {
              path: 'promociones',
              name:'promociones',
              component: promotion_list,
              meta:{ auth: true, permission: 'default'},
            },
            {
              path: 'configuracion',
              name:'Configuración',
              component: settings,
              meta:{ auth: true, permission: 'default'},
            },
            {
              path: 'cartas',
              component: menu_list,
              name:'menu_list',
              meta:{ auth: true, permission: 'Mostrar menu'},
            },
            {
              path: 'carta/:menu',
              component: menu_create,
              name:'menu_crear',
              meta:{ auth: true, permission: 'Crear menu'},
              beforeEnter: (to, from, next) => {
                let Exp = /^([0-9])*$/;
                if(Exp.test(to.params.menu) || to.params.menu === 'crear'){
                  next();
                }
                else{
                  next('/tc-admin/error');
                }
              }
            }
          ]
        },
        {
          path: 'restaurante/:id',
          component: restaurant_create,
          name:'Restaurante',
          meta:{ auth: true, permission: 'Crear restaurante'},
          beforeEnter: (to, from, next) => {
            let Exp = /^([0-9])*$/;
            if(Exp.test(to.params.id) || to.params.id === 'registrar'){
              next();
            }
            else{
              next('/tc-admin/error');
            }
          }
        },
        {
          path: 'platos',
          component: dishe_list,
          meta:{ auth: true, permission: 'Mostrar plato'},
        },
        {
          path: 'platos/:option',
          component: dishe,
          meta:{ auth: true, permission: 'Crear plato'},
          beforeEnter: (to, from, next) => {
            let Exp = /^([0-9])*$/;
            if(Exp.test(to.params.option) || to.params.option === 'crear'){
              next();
            }
            else{
              next('/tc-admin/error');
            }
          }
        },
        {
          path: 'categorias',
          name:'Categorías del platos',
          component: dishe_categories,
          meta:{ auth: true, permission: 'Mostrar categoria'},
        },
        {
          path: 'cartas',
          component: menu_list,
          name:'carta_list',
          meta:{ auth: true, permission: 'Mostrar menu'},
        },
        {
          path: 'carta/:menu',
          component: menu_create,
          meta:{ auth: true, permission: 'Crear menu'},
          beforeEnter: (to, from, next) => {
            let Exp = /^([0-9])*$/;
            if(Exp.test(to.params.menu) || to.params.menu === 'crear'){
              next();
            }
            else{
              next('/tc-admin/error');
            }
          }
        },
        {
          path: 'promociones/:promotion',
          component: promotion_create,
          name:'Promotion_create',
          meta:{ auth: true, permission: 'Crear promocion'},
          beforeEnter: (to, from, next) => {
            let Exp = /^([0-9])*$/;
            if(Exp.test(to.params.promotion) || to.params.promotion === 'crear'){
              next();
            }
            else{
              next('/tc-admin/error');
            }
          }
        },
        {
          path: 'promociones',
          component: promotion_list,
          name:'Promotion_list',
          meta:{ auth: true, permission: 'Mostrar promocion'},
        },
        {
          path: 'horarios',
          name:'horarios',
          component: horarios_list,
          meta:{ auth: true, permission: 'default'},
        },
        {
          path: 'reservas',
          name:'reservas',
          component: reservas_list,
          meta:{ auth: true, permission: 'default'},
        },
        {
          path: 'alergenos/',
          component: alergenos,
          name: 'Alérgenos',
          meta:{ auth: true, permission: 'Mostrar alergeno'},
        },
        {
          path: 'usuarios',
          component: users,
          name: 'Usuarios',
          meta:{ auth: true, permission: 'Mostrar usuarios'},
        },
        {
          path: 'configuraciones',
          component: settings,
          name: 'Configuraciones',
          meta:{ auth: true, permission: 'default'},
        },
        {
          path: 'error',
          component: error_admin,
          name: 'Error-admin',
          meta:{ auth: true, permission: 'default'},
        },
      ]
    },
  ],
})

routes.beforeEach((to, from, next)=>{
  if(to.meta.permission === 'landing'){
    next();
  }
  else{
    if(to.meta.auth != undefined){
      if (store.getters['auth/authenticated']) {
        if (!to.meta.auth) {
          next({ path: '/tc-admin' })
        }
        else{
          if (store.getters['auth/isAdmin']){
            requestApi.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['auth/token'];
            next();
          }
          else{
            if((permit(to.meta.permission) || to.meta.permission == 'default')){
              requestApi.defaults.headers.common['Authorization'] = 'Bearer ' + store.getters['auth/token'];
              next();
            }
            else{
              next({ path: '/tc-admin/error' })
            }
          }
        }

      }
      else{
        if(!to.matched.some(record => record.meta.auth)){
          console.log('entro record.meta.auth');
          next();
        }
        else{
          next(`/auth/login?redirect=${to.fullPath}`);
        }
      }
    }
    else{
      next({ path: '/404' })
    }
  }

});

export default routes;
