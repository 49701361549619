import * as Cookies from '../../resources/auth_user'
import requestApi from '../../resources/request'


const auth = {
  namespaced: true,
  state: {
    user: Cookies.GET_USER(),
    token: Cookies.GET_TOKEN(),
    permissions: Cookies.GET_PERMISSIONS() || [],
  },
  getters: {
    user (state) {
      return state.user;
    },

    token(state) {
      return state.token
    },

    permissions(state) {
      return state.permissions;
    },

    authenticated(state){
      return state.token !== null;
    },

    rol(state){
      return state.user ? state.user.roles[0].name : null;
    },

    logo(state){
      return state.user ? state.user.logo : null;
    },

    restaurant(state){
      return state.user.restaurant_id ? state.user.restaurant_id : null;
    },

    isAdmin(state){
      if(state.user)
      {
        switch(state.user.roles[0].id) {
          case 1:
            return true;
          case 2:
            return true;
        }
      }

      return false;
    }

  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },

    SET_LOGO(state, logo) {
      state.user.logo = logo;
    },

    SET_USERNAME(state, username) {
      state.user.username = username;
    },

    SET_TOKEN(state, token) {
      state.token = token;
    },

    SET_PERMISSIONS(state, permissions) {
      state.permissions = permissions;
    },

    LOGOUT_USER(state){
      state.user = null;
      state.token = null;
      state.permissions = [];
      Cookies.REMOVE_USER();
    }
  },
  actions: {
    async login({commit}, data){
      const response = await requestApi.post('api/auth/login', data);

      commit('SET_USER', response.data.user);
      commit('SET_TOKEN', response.data.access_token);
      commit('SET_PERMISSIONS', response.data.user.allPermission);

      Cookies.SET_USER(response.data.user);
      Cookies.SET_TOKEN(response.data.access_token);
      Cookies.SET_PERMISSIONS(response.data.user.allPermission);
    },

    async logout({commit}){

      await requestApi.get('api/auth/logout');

      Cookies.REMOVE_USER();
      commit('LOGOUT_USER');
    },

    closeSesion({commit}){
      Cookies.REMOVE_USER();
      commit('LOGOUT_USER');
    }
  },
}

export default auth;
