import Vue from 'vue'
import moment from 'moment'
// import es from 'moment/locale/es'

Vue.filter('parseEs', (value) => {
	const langs = JSON.parse(value);
    return langs.es;
});

Vue.filter('smartDate', (date) => {
  moment.locale('es');

  return moment(date).format('D MMM YYYY');
})