// =========================================================
// * Project Todo Carta Online
// =========================================================
//
//
// =========================================================
//

import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import './plugins/base'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import './plugins/webFontLoader'
import VueMeta from 'vue-meta'
import requestApi from './resources/request'
import './resources/filters'
import Permissions from './mixins/Permissions'
import PermissionSubmenu from './mixins/PermissionsSubmenu'
import VueI18n from 'vue-i18n'
import en_vuetify from 'vuetify/lib/locale/en'
import es_vuetify from 'vuetify/es5/locale/es'
import FlagIcon from 'vue-flag-icon'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueHtmlToPaper from 'vue-html-to-paper';
import './registerServiceWorker'
import Croppa from 'vue-croppa'
import numeral  from 'numeral'

/*
** Componente para la impresion del PDF Codigo QR
 */

//recortar imagenes
Vue.use(Croppa)


const url = process.env.VUE_APP_BASE_API + 'pdf.css';
const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    url,
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.css",
    "https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"]
}

Vue.use(VueHtmlToPaper, options);

/*
** Asigancion de axios a variable global
 */
Vue.prototype.$api = requestApi;

numeral.register('locale', 'es', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '€'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'co', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '$ COP'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 've', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: 'BsS'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'us', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '$ USD'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'br', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: 'R$'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'pe', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: 'S/'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'cc', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '$'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})
numeral.register('locale', 'mx', {
  delimiters: {
      thousands: '.',
      decimal: ','
  },
  currency: {
    symbol: '$'
  },
  abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't'
  }
})

Vue.prototype.$numeral = numeral

/*
** Asigancion de Vue meta
 */
Vue.use(VueMeta,{
  keyName: 'head',
});
/*
** Componente para banderas
 */
Vue.use(FlagIcon);
/*
** Componente para banderas
 */
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDaNna-4BztexfUxYleZThIixD0ZAgoz_0',
    //key: 'AIzaSyAxFzkrwUuWE-yeW4TKouth7bF2saA5iAg',
    libraries: 'places',
  },
  installComponents: true
})

/*
** Configuracion Global para el Componente de Idioma
 */
Vue.use(VueI18n);
const messages = {
  en: {
    ...require('@/locales/en.js'),
    $vuetify: en_vuetify,
  },
  es: {
    ...require('@/locales/es.js'),
    $vuetify: es_vuetify,
  },
  fr: {
    ...require('@/locales/fr.js'),
  },
  zh: {
    ...require('@/locales/zh.js'),
  }
}

const i18n = new VueI18n({
  locale: store.getters.language,
  fallbackLocale: store.getters.language,
  messages,
})

/*
** Mixin para evaluar los permisos de usuarios
 */
Vue.mixin(Permissions);
Vue.mixin(PermissionSubmenu);

/*
** Configuarcion de Vue
 */
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
