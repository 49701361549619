import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import VueI18n from 'vue-i18n'
import '@/sass/overrides.sass'
import '@mdi/font/css/materialdesignicons.css'
import '../assets/MyFontsWebfontsKit.css'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)
Vue.use(VueI18n)

const theme = {
  primary: '#283145',
  secondary: '#9C27b0',
  accent: '#3B3260',
  info: '#479ad4',
  success: '#479ad4',
  sidebar: '#2c364c',
  topBar: '#fbfcfd',
  landing_primary: '#FF760E'
}

const messages = {
  en: {
    $vuetify: es
  },
}

const i18n = new VueI18n({
  locale: 'en', // set locale
  messages, // set locale messages
})

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  iconfont: 'mdi'
})
