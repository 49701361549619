const fr = {
	btns:{
		carta: 'lettre',
		menu: 'Menús',
		promociones: 'Les Promotions',
		info: 'Info',
		secciones: 'Sections',
		alergenos: 'Allergènes',
		aplicar: 'Appliquer',
    select_todos: 'Tout sélectionner',
    shooping_cart: 'Panier',
    reservas: 'Reserva'
	},
	titulos:{
		secciones: 'Sections',
		alergenos: 'Allergènes',
		detalles: 'Détails',
		ubicacion: 'Emplacement',
	},
	plato:{
		precio: 'Prix',
		promociones: 'Les Promotions',
  },
  add_shopping_cart: 'Ajouter au Panier'
};

export default fr;
