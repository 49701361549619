import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import landing from './modules/landing'
import * as Cookies from '../resources/auth_user'

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    overlay: false,
    language: Cookies.GET_LANGUAGE() || 'es',
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: '',
    drawer: null
  },
  getters: {
    language (state) {
      return state.language;
    },
  },
  mutations: {
    SET_LANGUAGE (state, payload) {
      state.language = payload
      Cookies.SET_LANGUAGE(payload);
    },

    SET_OVERLAY (state, payload) {
      state.overlay = payload
    },

    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    }
  },

  actions: {

  },
  modules:{
    auth,
    landing
  }
})

export default store;
