const es = {
	btns:{
		carta: 'Carta',
		menu: 'Menús',
		promociones: 'Promociones',
		info: 'Info',
		secciones: 'Secciones',
		alergenos: 'Alérgenos',
		aplicar: 'Aplicar',
    select_todos: 'Seleccionar todos',
    shooping_cart: 'Carrrito',
    reservas: 'Reservar'
	},
	titulos:{
		secciones: 'Secciones',
		alergenos: 'Alérgenos',
		detalles: 'Detalles',
		ubicacion: 'Ubicación',
	},
	plato:{
		precio: 'Precio',
		promociones: 'Promociones',
  },
  add_shopping_cart: 'Añadir al Carrito'
};

export default es;
